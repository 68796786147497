import React from "react"
import styled from "styled-components"
import { Grid, Row, Col } from "@zendeskgarden/react-grid"
import { Button, FadeUp, StyledSpacer } from "../styles/ui"
import TrackVisibility from "react-on-screen"
import fonts from "../styles/fonts"
import { email } from "../content/content"
import { FaFacebookMessenger, FaYoutube } from "react-icons/fa"
import { MdMail } from "react-icons/md"
import { ImSoundcloud } from "react-icons/im"
import { RiFacebookCircleFill, RiInstagramFill } from "react-icons/ri"
import PatreonIcon from "../images/icons/icon_patreon.svg"

const PatreonButton = styled(Button)`
  background: rgb(251, 102, 78);
  color: rgb(255 255 255 / 0.8);
  text-transform: uppercase;
  padding-left: 0.2em;
  padding-top: 0;
  padding-bottom: 0.05em;
  line-height: 1.3;
  text-align: left;
  min-width: 12em;
  margin-top: 1em;
  &:hover,
  &:active {
    background: rgb(251, 102, 78);
    color: rgb(255 255 255 / 1);
  }
`

const partnerLinks = [
  {
    text: "Svenskt Kratom",
    url: "https://svenskt-kratom.se/",
  },
  {
    text: "Undrgrnd",
    url: "https://www.facebook.com/undrgrndamsterdam/",
  },
  {
    text: "SpaceNoodle",
    url: "http://spacenoodle.online/",
  },
  {
    text: "Kevin Valentine",
    url: "https://kevinvalentine.nl/",
  },
  {
    text: "Federico Allende",
    url: "http://fedeallende.com/",
  },
  {
    text: "Roots of Amsterdam",
    url: "https://poetisaraiz.wixsite.com/rootsofamsterdam",
  },
  {
    text: "House of Art",
    url: "https://houseofart.info/",
  },
  {
    text: "Radio UvA",
    url: "https://uvaradio.nl/",
  },
  {
    text: "Ultraviolet Festival",
    url: "https://ultraviolet.live/",
  },
]

const socialLinks = [
  {
    text: "Facebook",
    url: "https://www.facebook.com/RocketshipAmsterdam",
    icon: <RiFacebookCircleFill />,
  },
  {
    text: "Instagram",
    url: "https://www.instagram.com/rocketshiptrips/",
    icon: <RiInstagramFill />,
  },
  {
    text: "YouTube",
    url: "https://www.youtube.com/channel/UCOS39mnVbuN9cXaDUPAQtBA",
    icon: <FaYoutube />,
  },
  {
    text: "Soundcloud",
    url: "https://soundcloud.com/rocketship-trips",
    icon: <ImSoundcloud />,
  },
]

const contactLinks = [
  {
    text: email,
    url: `mailto:${email}`,
    icon: <MdMail />,
  },
  {
    text: "m.me/RocketshipAmsterdam",
    url: "https://m.me/RocketshipAmsterdam",
    icon: <FaFacebookMessenger />,
  },
]

const SContact = styled.section`
  border-top: 10px solid #2e2547;
  background-color: #1b1820;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23000000' fill-opacity='0.4' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
  padding: 4em 4em;
  h2 {
    font-family: ${fonts.heading};
    font-weight: 100;
    font-size: 2em;
  }
  h3 {
    font-family: ${fonts.heading};
    font-weight: 100;
  }
  ul {
    padding: 0;
  }
  p {
    font-size: 85%;
    margin-bottom: 2em;
  }
  .partners {
    margin-top: 2em;
    p {
      line-height: 2;
      font-size: 90%;
      margin: 0 -0.5em;
    }
    a {
      transition: all 0.2s;
      color: inherit;
      text-decoration: none;
      display: inline-block;
      padding: 0.5em;
      &:hover {
        color: rgb(255 255 255 / 75%);
      }
    }
  }
  @media (max-width: 450px) {
    padding: 3em;
    font-size: 95%;
  }
  @media (max-width: 360px) {
    padding: 1.5em 1.5em 2.5em;
  }
`

const SocialLink = styled.li`
  list-style: none;
  margin-bottom: 1em;
  a {
    display: flex;
    align-items: center;
    color: rgb(255 255 255 / 50%);
    text-decoration: none;
    transition: all 0.2s;
    &:hover {
      color: rgb(255 255 255 / 75%);
    }
  }
  svg {
    font-size: 150%;
    margin-right: 0.4em;
  }
`

const Contact = () => {
  return (
    <SContact id="footer">
      <Grid gutters="xl">
        <Row>
          <Col>
            <TrackVisibility>
              <FadeUp>
                <h2>Contact</h2>
              </FadeUp>
            </TrackVisibility>
            <ul>
              {contactLinks.map(({ url, text, icon }, i) => (
                <SocialLink key={i}>
                  <TrackVisibility>
                    <FadeUp>
                      <a href={url}>
                        {icon} {text}
                      </a>
                    </FadeUp>
                  </TrackVisibility>
                </SocialLink>
              ))}
            </ul>
            <div className="partners">
              <TrackVisibility>
                <FadeUp>
                  <h3>Partners &amp; friends</h3>
                </FadeUp>
              </TrackVisibility>
              <TrackVisibility>
                <FadeUp>
                  <p>
                    {partnerLinks.map(({ url, text }, i) => (
                      <a
                        href={url}
                        key={i}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {text}
                      </a>
                    ))}
                  </p>{" "}
                </FadeUp>
              </TrackVisibility>
            </div>
            <StyledSpacer />
          </Col>
          <Col>
            <TrackVisibility>
              <FadeUp>
                <h2>Social</h2>
              </FadeUp>
            </TrackVisibility>
            <ul>
              {socialLinks.map(({ url, text, icon }, i) => (
                <SocialLink key={i}>
                  <TrackVisibility>
                    <FadeUp>
                      <a href={url} target="_blank" rel="noopener noreferrer">
                        {icon} {text}
                      </a>
                    </FadeUp>
                  </TrackVisibility>
                </SocialLink>
              ))}
            </ul>
            <TrackVisibility>
              <FadeUp>
                <PatreonButton
                  size="large"
                  onClick={e =>
                    window.open(
                      "https://www.patreon.com/theRocketship",
                      "_blank"
                    )
                  }
                >
                  <img src={PatreonIcon} alt="Patreon logo icon" />
                  Support us
                  <br /> on Patreon
                </PatreonButton>
              </FadeUp>
            </TrackVisibility>
          </Col>
        </Row>
      </Grid>
    </SContact>
  )
}

export default Contact
