import React from "react"
import styled from "styled-components"
import { Grid, Row, Col } from "@zendeskgarden/react-grid"

const currentYear = new Date().getFullYear()

const SFooter = styled.footer`
  background: rgb(16 14 19);
  color: rgb(255 255 255 / 45%);
  padding: 0.75em;
  font-size: 0.85em;
  div {
    text-align: center;
  }
  div:first-child {
    text-align: left;
  }
  div:last-child {
    text-align: right;
  }
  @media (max-width: 575px) {
    div:first-child,
    div:last-child {
      text-align: center;
    }
  }
`

const Footer = () => {
  return (
    <SFooter>
      <Grid>
        <Row>
          <Col sm="6">
            &copy; Rocketship {currentYear}. Made with{" "}
            <span role="img" aria-label="heart">
              💜
            </span>
          </Col>
          <Col sm="6">
            Thank you for flying with us{" "}
            <span role="img" aria-label="rocket">
              🚀
            </span>
          </Col>
        </Row>
      </Grid>
    </SFooter>
  )
}

export default Footer
