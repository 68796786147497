import { ThemeProvider, DEFAULT_THEME } from "@zendeskgarden/react-theming"
import React from "react"
import { GlobalStyle } from "../styles/globalStyles"
import theme from "../styles/theme"
import Contact from "./Contact"
import Footer from "./Footer"
import NavBar from "./NavBar"
import loader from "../images/loader.svg"
import ConctactFormBlock from "./ConctactFormBlock"
import { ToastProvider } from "@zendeskgarden/react-notifications"

const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <ThemeProvider
        theme={{
          ...DEFAULT_THEME,
          colors: {
            ...DEFAULT_THEME.colors,
            base: "dark",
            background: theme.bg,
            primaryHue: theme.accent,
            neutralHue: "rgba(255,255,255,0.5)",
            chromeHue: "#000000",
          },
          borders: "0px solid",
        }}
      >
        <ToastProvider zIndex={10}>
          <GlobalStyle />
          <div className="content-wrap">
            <NavBar />
            <main>{children}</main>
            <ConctactFormBlock />
            <Contact />
            <Footer />
          </div>
          <img src={loader} alt="Packman loader" className="site-loader" />
        </ToastProvider>
      </ThemeProvider>
    </React.Fragment>
  )
}

export default Layout
