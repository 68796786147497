import styled, { css } from "styled-components"
import theme from "./theme"
import fonts from "./fonts"
import { darken } from "polished"

export const CenteredBox = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  box-sizing: border-box;
  @media (max-width: 1400px) {
    width: 80%;
  }
  @media (max-width: 500px) {
    width: 100%;
    padding: 0 2em;
  }
`

export const StaticCenteredBox = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 1500px;
  max-width: 100%;
  padding: 0 2em;
  box-sizing: border-box;
  @media (max-width: 1500px) {
    width: 945px;
  }
`

export const menuHeight = {
  large: 70,
  small: 50,
}

export const menuBreakpoint = 860

export const StyledSpacer = styled.div`
  height: ${p => p.theme.space.md};
`

export const Button = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: border-color 0.25s ease-in-out, box-shadow 0.1s ease-in-out,
    background-color 0.25s ease-in-out, color 0.25s ease-in-out;
  border: 1px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
  user-select: none;
  background: ${theme.accent};
  color: rgb(52 41 16);
  font-family: inherit;
  font-weight: 600;
  font-size: 1em;
  max-width: 100%;
  &.price-tag-btn {
    background: ${theme.accent};
    padding: 0.5em 0.8em;
    color: black;
    font-family: ${fonts.hand};
    font-size: 2em;
    font-weight: bold;
    border-radius: 0;
  }
  &:hover {
    background: ${darken(0.1, theme.accent)};
    color: rgb(52 41 16);
  }
  &:focus {
    outline: none;
    color: rgb(52 41 16);
  }
  &:active {
    background: ${darken(0.15, theme.accent)};
    color: rgb(52 41 16);
  }
  &:disabled {
    color: rgb(0 0 0 / 20%);
    cursor: not-allowed;
  }
  width: ${props => (props.isStretched ? "100%" : "auto")};
  padding: ${props => (props.isLarge ? "1em 3em" : "0.5em 1.5em")};
  svg {
    font-size: 1.5em;
    margin: 0 0 0 0.3em;
  }
`

export const FadeUp = styled.div`
  opacity: 0;
  ${({ isVisible }) =>
    isVisible &&
    css`
      animation: fadeUp 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
    `}
  @keyframes fadeUp {
    0% {
      opacity: 0;
      transform: translateY(50px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`

export const FadeScale = styled.div`
  opacity: 0;
  ${({ isVisible }) =>
    isVisible &&
    css`
      animation: fadeScale 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
    `}
  @keyframes fadeScale {
    0% {
      opacity: 0;
      transform: scale(0.8);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
`

export const BlockIcon = styled.div`
  svg {
    display: block;
    width: 175px;
    height: 175px;
    margin: auto;
    padding: 2em;
    max-width: 100%;
    box-sizing: border-box;
  }
`
