import React, { useState } from "react"
import styled from "styled-components"
import fonts from "../styles/fonts"
import { RiMailSendLine, RiMenuFill } from "react-icons/ri"
import { Link as ScrollLink } from "react-scroll"
import { menuHeight, menuBreakpoint } from "../styles/ui"
import theme from "../styles/theme"
import { darken } from "polished"
import { DrawerModal } from "@zendeskgarden/react-modals"
import { Link } from "gatsby"
import Logo from "./Logo"

const menuLinks = [
  {
    text: "Home",
    url: "/",
  },
  {
    text: "Services",
    url: "/services",
  },
  {
    text: "Studio",
    url: "/studio",
  },
  {
    text: "HQ",
    url: "/rocketship-hq",
  },
  {
    text: "Events",
    url: "/events",
  },
  {
    text: "Contact",
    url: "contact",
    scroll: true,
  },
]

export const getMenuHeight = () => {
  if (typeof window !== "undefined") {
    return window.innerWidth > menuBreakpoint
      ? menuHeight.large
      : menuHeight.small
  } else {
    return 0
  }
}

const SNavBar = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75em 1.5em;
  position: fixed;
  background: ${props =>
    props.isScrolledTop ? "transparent" : darken(0.05, theme.bg)};
  transition: background 0.5s;
  left: 0;
  right: 0;
  top: 0;
  height: ${menuHeight.large}px;
  box-sizing: border-box;
  z-index: 9;
  pointer-events: none;
  .logo-link {
    pointer-events: all;
    width: 225px;
    svg {
      display: block;
      width: auto;
      height: 100%;
    }
  }
  .icon-link {
    pointer-events: all;
    color: rgba(255, 255, 255, 0.7);
    font-size: 150%;
    transition: color 0.4s;
    margin-left: 1em;
    &:hover {
      color: white;
    }
  }
  .menu-link {
    display: none;
  }
  .mob-nav {
    display: none;
  }
  @media (max-width: ${menuBreakpoint}px) {
    height: ${menuHeight.small}px;
    padding-top: 1em;
    .menu-link {
      display: inline-block;
    }
    .main-nav {
      display: none;
    }
    .mob-nav {
      display: flex;
    }
    .logo-link {
      height: 35px;
    }
  }
`

const LogoLink = styled.a`
  font-family: ${fonts.heading};
  font-size: 1.5em;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #d8d8d8;
  transition: color 0.4s, transform 0.4s, opacity 0.4s;
  user-select: none;
  opacity: 0.8;
  &:hover {
    color: rgb(255 255 255 / 95%);
    opacity: 1;
  }
  img {
    height: 2em;
    margin: 0 0.5em;
  }
`

const MainNav = styled.nav`
  a {
    pointer-events: all;
    display: inline-block;
    font-size: 1.15em;
    margin: 0 1em;
    text-decoration: none;
    color: rgb(255 255 255 / 50%);
    transition: color 0.4s;
    &:hover,
    &:active {
      color: rgb(255 255 255 / 75%);
    }
    &.active {
      color: ${theme.accent};
    }
  }
`
const DrawerWrap = styled.div`
  padding-top: 2.5em;
  text-align: right;
  .drawer-link {
    display: block;
    padding: 1em;
    border-bottom: 1px solid rgb(255 255 255 / 5%);
    text-decoration: none;
    transition: color 0.2s, background 0.2s;
    &:hover {
      background: rgb(255 255 255 / 2%);
    }
  }
  .menu-doodle {
    position: absolute;
    right: 3em;
    bottom: 2em;
    margin: auto;
    opacity: 0.3;
    transition: transform 0.2s;
    &:hover {
      transform: rotate(5deg);
    }
  }
`

const MobileMenu = () => {
  const [isOpen, setIsOpen] = useState(false)
  const open = () => setIsOpen(true)
  const close = () => setIsOpen(false)
  return (
    <React.Fragment>
      <RiMenuFill className="icon-link" onClick={open} title="Open menu" />
      <DrawerModal
        isOpen={isOpen}
        onClose={close}
        backdropProps={{ className: "bg-tr-black" }}
        className="mob-menu"
      >
        <DrawerModal.Body>
          <DrawerWrap>
            {menuLinks.map(({ text, url, scroll }) =>
              !scroll ? (
                <Link to={url} key={url} className="drawer-link">
                  {text}
                </Link>
              ) : (
                <ScrollLink
                  className="drawer-link"
                  to="contact"
                  smooth={true}
                  duration={500}
                  offset={-getMenuHeight()}
                  href="#contact"
                  title="Contact"
                  onClick={close}
                >
                  {text}
                </ScrollLink>
              )
            )}
          </DrawerWrap>
        </DrawerModal.Body>
        <DrawerModal.Close />
      </DrawerModal>
    </React.Fragment>
  )
}

const NavBar = () => {
  const [isTop, setIsTop] = useState(true)
  if (typeof window !== "undefined") {
    window.addEventListener("scroll", () =>
      window.scrollY === 0 ? setIsTop(true) : setIsTop(false)
    )
  }
  return (
    <React.Fragment>
      <SNavBar className="nav-bar" isScrolledTop={isTop}>
        <LogoLink as={Link} className="logo-link" to="/" aria-label="Logo">
          <Logo />
        </LogoLink>
        <MainNav className="main-nav">
          {menuLinks.map(({ text, url, scroll }) =>
            !!scroll ? (
              <ScrollLink
                key={url}
                to={url}
                smooth={true}
                duration={500}
                offset={-getMenuHeight()}
                href={url}
              >
                {text}
              </ScrollLink>
            ) : (
              <Link to={url} key={url} activeClassName="active">
                {text}
              </Link>
            )
          )}
        </MainNav>
        <nav className="mob-nav">
          <ScrollLink
            to="contact"
            smooth={true}
            duration={500}
            offset={-getMenuHeight()}
            href="#contact"
            title="Contact"
          >
            <RiMailSendLine className="icon-link" />
          </ScrollLink>
          <MobileMenu />
        </nav>
      </SNavBar>
    </React.Fragment>
  )
}

export default NavBar
