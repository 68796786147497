import React from "react"
import TrackVisibility from "react-on-screen"
import { FadeUp } from "../styles/ui"

const WrapFadeUp = ({ children }) => {
  return (
    <TrackVisibility>
      <FadeUp>{children}</FadeUp>
    </TrackVisibility>
  )
}

export default WrapFadeUp
