import React, { useRef, useState } from "react"
import { useFormspark } from "@formspark/use-formspark"
import { Field, Label, Input, Textarea } from "@zendeskgarden/react-forms"
import { BlockIcon, Button, StyledSpacer } from "../styles/ui"
import { useForm, Controller } from "react-hook-form"
import styled from "styled-components"
import { RiMailSendLine } from "react-icons/ri"
import {
  Notification,
  Title,
  Close,
  useToast,
} from "@zendeskgarden/react-notifications"
import { ThemeProvider, DEFAULT_THEME } from "@zendeskgarden/react-theming"
import theme from "../styles/theme"
import WrapFadeUp from "../components/WrapFadeUp"
import { ImPacman, ImShocked2 } from "react-icons/im"
import { Link as ScrollLink } from "react-scroll"
import { getMenuHeight } from "./NavBar"
import Botpoison from "@botpoison/browser"
import { Inline } from "@zendeskgarden/react-loaders"

const botpoison = new Botpoison({
  publicKey: "pk_6ded6802-eaa6-4798-9dfc-e3e0a0aa5e1d",
})

const SForm = styled.form`
  margin: auto;
  width: 400px;
  max-width: 100%;
  .btn-wrap {
    text-align: center;
    padding-top: 1.5em;
  }
`

const ContactForm = () => {
  const [submit] = useFormspark({
    formId: "zr4wpHSY",
  })
  const { control, handleSubmit, reset } = useForm({
    defaultValues: { name: "", email: "", phone: "", message: "" },
  })
  const [btnDisabled, setBtnDisabled] = useState(false)
  const { addToast } = useToast()
  const onStartSubmit = () => setBtnDisabled(true)
  const onSubmit = async data => {
    const { solution } = await botpoison.challenge()
    onStartSubmit()
    submit({ ...data, _botpoison: solution })
      .then(res => {
        addToast(
          ({ close }) => (
            <Notification type="success" style={{ maxWidth: 450 }}>
              <Title>Thank you for getting in touch!</Title>
              We'll get back to you ASAP
              <BlockIcon>
                <ImPacman />
              </BlockIcon>
              <Close aria-label="Close" onClick={close} />
            </Notification>
          ),
          { placement: "top-end" }
        )
        reset()
        setBtnDisabled(false)
      })
      .catch(error => {
        addToast(
          ({ close }) => (
            <Notification type="error">
              <Title>Oopsie, something went wrong ^_^</Title>
              No worries, here are some{" "}
              <ScrollLink
                to="footer"
                smooth={true}
                duration={500}
                offset={-getMenuHeight()}
                href="#footer"
                title="Contact info"
              >
                other ways to contact us
              </ScrollLink>
              <BlockIcon>
                <ImShocked2 />
              </BlockIcon>
              <Close aria-label="Close" onClick={close} />
            </Notification>
          ),
          { placement: "top-end" }
        )
        reset()
        setBtnDisabled(false)
      })
  }
  const submitBtn = useRef(null)
  const handleEnter = e => {
    if (e.key === "Enter") {
      e.preventDefault()
      onStartSubmit()
      submitBtn.current.click()
      submitBtn.current.focus()
    }
  }
  return (
    <React.Fragment>
      <StyledSpacer />
      <ThemeProvider
        theme={{
          ...DEFAULT_THEME,
          colors: {
            ...DEFAULT_THEME.colors,
            base: "dark",
            background: "rgb(255 255 255 / 13%)",
            foreground: "rgb(255 255 255 / 60%)",
            neutralHue: "#ffffff3b",
            primaryHue: theme.accent,
          },
        }}
      >
        <SForm onSubmit={handleSubmit(onSubmit)}>
          <WrapFadeUp>
            <Field>
              <Label hidden>Name</Label>
              <Controller
                name="name"
                control={control}
                rules={{ required: true, maxLength: 30 }}
                render={({ field, fieldState }) => (
                  <Input
                    placeholder="Name*"
                    {...field}
                    validation={fieldState.invalid ? "error" : null}
                  />
                )}
              />
            </Field>
          </WrapFadeUp>
          <StyledSpacer />
          <WrapFadeUp>
            <Field>
              <Label hidden>Email*</Label>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: true,
                  maxLength: 30,
                  pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                }}
                render={({ field, fieldState }) => (
                  <Input
                    placeholder="Email*"
                    type="email"
                    {...field}
                    validation={fieldState.invalid ? "error" : null}
                  />
                )}
              />
            </Field>
          </WrapFadeUp>
          <StyledSpacer />
          <WrapFadeUp>
            <Field>
              <Label hidden>Phone number*</Label>
              <Controller
                name="phone"
                control={control}
                rules={{ maxLength: 12, minLength: 9 }}
                render={({ field, fieldState }) => (
                  <Input
                    placeholder="Phone"
                    type="number"
                    {...field}
                    validation={fieldState.invalid ? "error" : null}
                  />
                )}
              />
            </Field>
          </WrapFadeUp>
          <StyledSpacer />
          <WrapFadeUp>
            <Field>
              <Label hidden>Message</Label>
              <Controller
                name="message"
                control={control}
                rules={{ required: true, maxLength: 200 }}
                render={({ field, fieldState }) => (
                  <Textarea
                    placeholder="Message or question*"
                    {...field}
                    minRows={5}
                    maxLength={1000}
                    validation={fieldState.invalid ? "error" : null}
                    onKeyPress={handleEnter}
                  />
                )}
              />
            </Field>
          </WrapFadeUp>
          <WrapFadeUp>
            <div className="btn-wrap">
              <Button
                type="submit"
                disabled={btnDisabled}
                isStretched
                isLarge
                ref={submitBtn}
              >
                Send {btnDisabled ? <Inline size={32} /> : <RiMailSendLine />}
              </Button>
            </div>
          </WrapFadeUp>
        </SForm>
      </ThemeProvider>
    </React.Fragment>
  )
}

export default ContactForm
