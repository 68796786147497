import { createGlobalStyle } from "styled-components"
import theme from "./theme"
import fonts from "./fonts"
import { darken } from "polished"

export const GlobalStyle = createGlobalStyle`
  html {
    .content-wrap {
      opacity: 0;
      transition: opacity .2s;
    }
    &.wf-active {
      .content-wrap {
        opacity: 1;
      }
      .site-loader {
        display: none;
      }
    }
  }
  .site-loader {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: -100px;
    margin-top: -100px;
  }
  body {
    margin: 0;
    padding: 0;
    font-size: 15px;
    font-family: ${fonts.default}, sans-serif;
    font-weight: 300;
    background: ${theme.bg};
    color: rgba(255, 255, 255, 0.6);
  }
  .mob-menu {
    box-shadow: 0 20px 28px 0 rgb(0 0 0 / 35%);
  }
  a {
    color: ${theme.accent};
    transition: color 0.2s;
    &:hover,
    &:focus,
    &:active {
      color: ${darken(0.1, theme.accent)};
    }
  }
  h1, h2, h3, h4 {
    font-weight: normal;
    margin-top: 0;
  }
  p {
    margin-top:0;
  }
  img {
    display: block;
    max-width: 100%;
    margin: auto;
  }
  .notebook-bg {
    background-size: 20px 20px;
    background-image: linear-gradient(0deg,transparent 94%,#24202b 100%), linear-gradient(90deg,transparent 94%,#24202b 100%);
  }
  .hand-font {
    font-family: ${fonts.hand};
    font-size: 200%;
    @media (max-width: 975px) {
      font-size: 150%;
    }
  }
  .hover-zoom {
    transition: transform .4s;
    &:hover {
      transform: scale(1.05,1.05);
    } 
  }
  .icon-inline {
    margin-right: .4em;
    font-size: 1.2em;
    vertical-align: -.1em;
  }
  .ul-spaced {
    list-style: square;
    li {
      margin-bottom: 1em;
    }
  }
  .col-count-2 {
    column-count: 2;
  }
  .bg-tr-black {
    background: rgba(0, 0, 0, 0.8);
  }
  .hover-zoom {
    transition: transform 2s;
    &:hover {
      transform: scale(1.1, 1.1);
    }
  }
  .border-top {
    border-top: 10px solid #2e2547;
  }
`
