import React from "react"
import styled, { css } from "styled-components"
import fonts from "../styles/fonts"

const SSection = styled.section`
  padding: ${props => (props.noPaddingBottom ? "6em 0 0" : "6em 0 7em")};
  ${({ background }) =>
    background &&
    css`
      background: ${background};
    `};
  @media (max-width: 800px) {
    padding: ${props => (props.noPaddingBottom ? "3em 0 0" : "3em 0")};
  }
  .section-head-wrap {
    text-align: center;
    margin: 0 auto 3em;
    width: 80%;
    padding-top: 1em;
    h1,
    h2,
    h3,
    h4 {
      font-family: ${fonts.heading};
      line-height: 1.6;
      margin-bottom: 0;
    }
    h1 {
      font-size: 2em;
    }
    .sub-head {
      padding-top: 1em;
      line-height: 1.8;
      font-family: ${fonts.heading};
    }
    @media (min-width: 800px) {
      font-size: 150%;
      margin-bottom: 4em;
    }
  }
  position: relative;
  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: ${({ backgroundImage }) =>
      backgroundImage ? `url(${backgroundImage})` : "none"};
    background-size: cover;
    opacity: 0.5;
    pointer-events: none;
  }
`

const Section = ({
  background,
  children,
  className,
  backgroundImage,
  noPaddingBottom,
  id,
}) => {
  return (
    <SSection
      className={className}
      background={background}
      backgroundImage={backgroundImage}
      noPaddingBottom={noPaddingBottom}
      id={id}
    >
      {children}
    </SSection>
  )
}

export default Section
